const PROMO_CODE_TYPE_ABSOLUTE_VALUE = 'absoluteValue';
const PROMO_CODE_TYPE_PERCENT = 'percent';

export const totalSum = state => productsSum(state) - promoCodeCalculator(state) + calculateDeliveryPrice(state) - state.customer.paidByBonuses

export const productsSum = state => state.products.reduce(
    (acc, product) => acc + (
        (
            product.price + product.modifiers.reduce(
                (totalSumLoc, modifier) => totalSumLoc + modifier.price,
                0,
            )
        ) * product.quantity
    ),
    0,
)

export const totalQuantity = state => state.products.reduce(
    (acc, product) => acc + product.quantity,
    0,
)

export const draftQuantity = state => id => {
    const product = state.draftProducts.find(p => p.id === id)

    return product ? product.quantity : 1
}

export const draftSum = state => (id, defaultPrice) => {
    const product = state.draftProducts.find(p => p.id === id)

    return product ? (product.price * product.quantity) : defaultPrice
}

export const discriminatedDraftQuantity = state => (id, discriminator) => {
    const product = state.draftProducts.find(p => p.id === id)

    if (product) {
        const discriminated = product.discriminated.find(d => d.discriminator === discriminator)

        if (discriminated) {
            return discriminated.quantity
        }
    }

    return 1
}

const calculatePrice = (state, deliveryPrices) => {
    return deliveryPrices[Object.keys(deliveryPrices)
        .sort((a, b) => b - a)
        .find(minPrice => productsSum(state) >= minPrice)
        ]
}


export const calculateDeliveryPrice = (state) =>
    state.daData.deliveryPrices ? calculatePrice(state, state.daData.deliveryPrices) : null

export const calculateDeliveryPriceLocal = (state) =>
    state.daData.deliveryPricesTest ? calculatePrice(state, state.daData.deliveryPricesTest) : null


export const calculateBonusesAvailableToUse = (state) => {
    const productsSumValue = productsSum(state)
    if (state.bonusSystem) {
        return state.bonusSystem.bonusesLimitPerPayment * productsSumValue / 100
    }
}

export const isCustomerHaveBonuses = (state) => {
    return state.authenticatorData.client.bonuses && state.authenticatorData.client.bonuses !== 0
}

export const promoCodeCalculator = (state) => {
    const productsSumValue = productsSum(state)
    if (state.promoCode.promoCodeFromServer) {
        switch (state.promoCode.promoCodeFromServer.discountType) {
            case PROMO_CODE_TYPE_PERCENT:
                return productsSumValue * state.promoCode.promoCodeFromServer.discount / 100;
            case PROMO_CODE_TYPE_ABSOLUTE_VALUE:
                return state.promoCode.promoCodeFromServer.discount;
            default:
                return null;
        }
    }

    return null
}

export const discriminatedDraftTotalSum = state => (id, defaultPrice, discriminator) => {
    const product = state.draftProducts.find(p => p.id === id)
    const discriminated = product
    && product.discriminated.find(d => d.discriminator === discriminator)

    return discriminated
        ? discriminated.quantity * product.price
        : defaultPrice
}

export const availableParameters = state => (productId, discriminator) => {
    /* eslint-disable no-nested-ternary */
    const availableParametersLoc = state.availableParameters
        .map(parameter => ({ // map all parameter to sorted parameters
            key: parameter.key,
            values: parameter.values
                .filter(p => String(p) === '0' || !!p) // remove all falsy values except '0' and 0
                .sort((a, b) => ((Number.isNaN(a) || Number.isNaN(b))
                    ? (a > b ? 1 : -1)
                    : (Number(a) > Number(b) ? 1 : -1))),
        }))

    const draft = state.draftProducts.find(p => p.id === productId)

    const additionalParameters = draft
        ? discriminator
            ? (
                draft.discriminated.find(d => d.discriminator === discriminator)
                || { additionalParameters: [] }
            ).additionalParameters || []
            : (draft || { additionalParameters: [] }).additionalParameters || []
        : []
    /* eslint-enable no-nested-ternary */

    return [
        ...availableParametersLoc
            .filter(parameter => !additionalParameters.map(p => p.key).includes(parameter.key)),
        ...additionalParameters,
    ]
}

export const getApiIdListFromCart = state => state.products.map(p => +p.apiId)

export const getProductQuantity = state => productApiId => state.products.find(p => p.apiId === productApiId).quantity
